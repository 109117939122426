const INITIAL_STATE = {
  cameras: false,
  clusters: false,
  informacoes: false,
  analise: false,
  rotas: false,
  rotasEvasao: false,
  avancadas: false,
  avancadasClippings: false,
  showRiskAreasExpanded: false,
  drawingManager: false,
  legendas: false,
  incidentes: false,
  mapTypesMenu: false,
  filters: false,
  drawingManagerAnalyses: false,
  careIncidents: false,
};

export default function animateMenu(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ABRIR_MENU_CAMERAS":
      return {
        cameras: true,
        clusters: false,
        informacoes: false,
        analise: false,
        rotas: false,
        rotasEvasao: false,
        legendas: false,
        avancadas: false,
        avancadasClippings: false,
        showRiskAreasExpanded: false,
        incidentes: false,
        mapTypesMenu: false,
        filters: false,
        careIncidents: false,
        drawingManager: state.drawingManager,
        drawingManagerAnalyses: state.drawingManagerAnalyses,
      };
    case "ABRIR_MENU_CLUSTERS":
      return {
        cameras: false,
        clusters: true,
        informacoes: false,
        analise: false,
        rotas: false,
        rotasEvasao: false,
        legendas: false,
        avancadas: false,
        avancadasClippings: false,
        showRiskAreasExpanded: false,
        incidentes: false,
        mapTypesMenu: false,
        filters: false,
        careIncidents: false,
        drawingManager: state.drawingManager,
        drawingManagerAnalyses: state.drawingManagerAnalyses,
      };
    case "ABRIR_MENU_INFORMACOES":
      return {
        cameras: false,
        clusters: false,
        informacoes: true,
        analise: false,
        rotas: false,
        rotasEvasao: false,
        legendas: false,
        avancadas: false,
        avancadasClippings: false,
        showRiskAreasExpanded: false,
        incidentes: false,
        mapTypesMenu: false,
        filters: false,
        careIncidents: false,
        drawingManager: state.drawingManager,
        drawingManagerAnalyses: state.drawingManagerAnalyses,
      };
    case "ABRIR_MENU_ANALISE":
      return {
        cameras: false,
        clusters: false,
        informacoes: false,
        analise: true,
        rotas: false,
        rotasEvasao: false,
        legendas: false,
        avancadas: false,
        avancadasClippings: false,
        showRiskAreasExpanded: false,
        incidentes: false,
        mapTypesMenu: false,
        filters: false,
        careIncidents: false,
        drawingManager: state.drawingManager,
        drawingManagerAnalyses: state.drawingManagerAnalyses,
      };
    case "ABRIR_MENU_ROTAS":
      return {
        cameras: false,
        clusters: false,
        informacoes: false,
        analise: false,
        rotas: true,
        rotasEvasao: false,
        legendas: false,
        avancadas: false,
        avancadasClippings: false,
        showRiskAreasExpanded: false,
        incidentes: false,
        mapTypesMenu: false,
        filters: false,
        careIncidents: false,
        drawingManager: state.drawingManager,
        drawingManagerAnalyses: state.drawingManagerAnalyses,
      };
    case "ABRIR_MENU_ROTAS_EVASAO":
      return {
        cameras: false,
        clusters: false,
        informacoes: false,
        analise: false,
        rotas: false,
        rotasEvasao: true,
        legendas: false,
        avancadas: false,
        avancadasClippings: false,
        showRiskAreasExpanded: false,
        incidentes: false,
        mapTypesMenu: false,
        filters: false,
        careIncidents: false,
        drawingManager: state.drawingManager,
        drawingManagerAnalyses: state.drawingManagerAnalyses,
      };
    case "ABRIR_MENU_LEGENDAS":
      return {
        cameras: false,
        clusters: false,
        informacoes: false,
        analise: false,
        rotas: false,
        rotasEvasao: false,
        legendas: true,
        avancadas: false,
        avancadasClippings: false,
        showRiskAreasExpanded: false,
        incidentes: false,
        mapTypesMenu: false,
        filters: false,
        careIncidents: false,
        drawingManager: state.drawingManager,
        drawingManagerAnalyses: state.drawingManagerAnalyses,
      };
    case "ABRIR_MENU_INCIDENTES":
      return {
        cameras: false,
        clusters: false,
        informacoes: false,
        analise: false,
        rotas: false,
        rotasEvasao: false,
        legendas: false,
        avancadas: false,
        avancadasClippings: false,
        showRiskAreasExpanded: false,
        incidentes: true,
        mapTypesMenu: false,
        filters: false,
        careIncidents: false,
        drawingManager: state.drawingManager,
        drawingManagerAnalyses: state.drawingManagerAnalyses,
      };
    case "ABRIR_MENU_FILTERS":
      return {
        cameras: false,
        clusters: false,
        informacoes: false,
        analise: false,
        rotas: false,
        rotasEvasao: false,
        legendas: false,
        avancadas: false,
        avancadasClippings: false,
        showRiskAreasExpanded: false,
        incidentes: false,
        mapTypesMenu: false,
        filters: true,
        careIncidents: false,
        drawingManager: state.drawingManager,
        drawingManagerAnalyses: state.drawingManagerAnalyses,
      };
    case "ABRIR_MENU_MAP_TYPES":
      return {
        ...INITIAL_STATE,
        mapTypesMenu: true,
      };
    case "FECHA_MENU":
      return {
        ...INITIAL_STATE,
        drawingManager: state.drawingManager,
        drawingManagerAnalyses: state.drawingManagerAnalyses,
      };
    case "ABRIR_DRAWING_MANAGER":
      return {
        ...state,
        drawingManager: !state.drawingManager,
        drawingManagerAnalyses: state.drawingManagerAnalyses,
      };
    case "ABRIR_DRAWING_MANAGER_ANALYSES":
      return {
        ...state,
        drawingManagerAnalyses: !state.drawingManagerAnalyses,
      };
    case "FECHAR_DRAWING_MANAGER_ANALYSES":
      return {
        ...state,
        drawingManagerAnalyses: false,
      };
    case "ABRIR_AVANCADAS":
      return {
        ...state,
        avancadas: true,
      };
    case "OCULTA_AVANCADAS":
      return {
        ...state,
        avancadas: false,
      };
    case "ABRIR_AVANCADAS_CLIPPINGS":
      return {
        ...state,
        avancadasClippings: true,
      };
    case "OCULTA_AVANCADAS_CLIPPINGS":
      return {
        ...state,
        avancadasClippings: false,
      };
    case "EXPAND_RISK_AREAS_MENU":
      return {
        ...state,
        showRiskAreasExpanded: true,
      };
    case "SHRINK_RISK_AREAS_MENU":
      return {
        ...state,
        showRiskAreasExpanded: false,
      };
    case "ABRIR_MENU_CARE":
      return {
        cameras: false,
        clusters: false,
        informacoes: false,
        analise: false,
        rotas: false,
        rotasEvasao: false,
        legendas: false,
        avancadas: false,
        avancadasClippings: false,
        showRiskAreasExpanded: false,
        incidentes: false,
        mapTypesMenu: false,
        filters: false,
        careIncidents: true,
        drawingManager: state.drawingManager,
        drawingManagerAnalyses: state.drawingManagerAnalyses,
      };
    case "OCULTAR_MENU_CARE":
      return {
        ...state,
        careIncidents: false,
      };
    default:
      return state;
  }
}
