import { Types } from "../actions/ocurrences";

const INITIAL_STATE = {
  activeIncidents: false,
  incidents: false,
  incidentsCompare: false,
  clippings: false,
  clippingsCompare: false,
  loadingAnalisys: false,
  loading: false,
  trafficEvents: true,
  occurenceTypesIds: [],
};
export default function list(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_ANALYSIS:
      return {
        ...state,
        loadingAnalisys: true,
      };
    case Types.GET_ANALYSIS_SUCCESS:
      return {
        ...state,
        incidents: action.list.incidents,
        clippings: action.list.clippings,
        loadingAnalisys: false,
      };
    case Types.GET_ANALYSIS_FAILURE:
      return {
        ...state,
        incidents: false,
        clippings: false,
        loadingAnalisys: false,
      };
    case Types.GET_ANALYSIS_COMPARE:
      return {
        ...state,
        loadingAnalisys: true,
      };
    case Types.GET_ANALYSIS_SUCCESS_COMPARE:
      return {
        ...state,
        incidentsCompare: action.list.incidents,
        clippingsCompare: action.list.clippings,
        loadingAnalisys: false,
      };
    case Types.GET_ANALYSIS_FAILURE_COMPARE:
      return {
        ...state,
        incidentsCompare: false,
        clippingsCompare: false,
        loadingAnalisys: false,
      };
    case Types.GET_INCIDENTS:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_INCIDENTS_SUCCESS:
      return {
        ...state,
        activeIncidents: action.list.data,
        loading: false,
      };
    case Types.GET_INCIDENTS_FAILURE:
      return {
        ...state,
        activeIncidents: false,
        loading: false,
      };
    case Types.STOP_TRAFFIC:
      return {
        ...state,
        trafficEvents: false,
      };
    case Types.START_TRAFFIC:
      return {
        ...state,
        trafficEvents: true,
      };
    case Types.UPDATE_OCCURENCE_ID:
      return {
        ...state,
        occurenceTypesIds: action.ids,
      };
    case Types.CLEAR_ANALYSIS:
      return {
        ...state,
        incidents: false,
        incidentsCompare: false,
        clippings: false,
        clippingsCompare: false,
        loading: false,
        loadingAnalisys: false,
      };
    case Types.CLEAR_ANALYSIS_COMPARE:
      return {
        ...state,
        incidentsCompare: false,
        clippingsCompare: false,
        loading: false,
        loadingAnalisys: false,
      };
    default:
      return state;
  }
}
