import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, getFormValues } from "redux-form";
import { Alert } from "@mui/lab";

import { Creators as Auth } from "../../store/actions/auth";
import { Snackbar } from "@mui/material";

import { SubmitButton } from "./styles";
import { FormInformation, InputLogin, ReturnToLogin, Text } from "./styles";
import LogoImage from "../../assets/images/logo.svg";

let LoginForm = (props) => {
  const [showSnackbar, setShowSnackBar] = useState(false);

  const handleShowSnackbar = () => {
    setShowSnackBar((state) => !state);
  };

  return (
    <>
      <img style={{ width: "285px" }} src={LogoImage} alt="Logo Geoanalytics" />

      <FormInformation padding={"0px"}>
        <strong>Esqueceu a senha?</strong>
      </FormInformation>
      <FormInformation padding={"0 10px"} marginBottom={"10px"}>
        Não se preocupe, enviaremos um <br />
        e-mail com as instruções para que <br />
        você altere a sua senha.
      </FormInformation>

      <form onSubmit={handleShowSnackbar} style={{ width: "100%" }}>
        <InputLogin
          placeholder="Digite aqui o seu e-mail"
          type="email"
          name="username"
          required
        />
        <SubmitButton type="submit" variant="contained" fullWidth size="large">
          <Text color={"#000000"}>Alterar Senha</Text>
        </SubmitButton>
        <ReturnToLogin onClick={props.openForgotPasswordForm}>
          <Text color={"#14454E"}>Voltar para o login</Text>
        </ReturnToLogin>
      </form>

      {showSnackbar && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          onClose={handleShowSnackbar}
          autoHideDuration={2000}
        >
          <Alert severity="success">Email enviado com sucesso!</Alert>
        </Snackbar>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    formFields: getFormValues("LOGIN_FORM")(state),
  };
};

const mapDispatchtoProps = (dispatch) => bindActionCreators(Auth, dispatch);

LoginForm = reduxForm({ form: "LOGIN_FORM" })(LoginForm);

export default connect(mapStateToProps, mapDispatchtoProps)(LoginForm);
