const INITIAL_STATE = {
  searchingByMarker: false,
  marker: false,
};

export default function activateCoords(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SELECT_MARKER_POINT":
      return {
        ...state,
        searchingByMarker: true,
        marker: false,
      };
    case "MARKER_SELECTED":
      return {
        ...state,
        searchingByMarker: false,
        marker: true,
      };
    case "REMOVE_MARKER":
      return {
        ...state,
        marker: false,
      };
    case "CANCEL_SELECT_MARKER":
      return {
        searchingByMarker: false,
        marker: false,
      };
    default:
      return state;
  }
}
