export const Types = {
  GET_ANALYSIS: "list/GET_ANALYSIS",
  GET_ANALYSIS_SUCCESS: "list/GET_ANALYSIS_SUCCESS",
  GET_ANALYSIS_FAILURE: "list/GET_ANALYSIS_FAILURE",
  GET_ANALYSIS_COMPARE: "list/GET_ANALYSIS_COMPARE",
  GET_ANALYSIS_SUCCESS_COMPARE: "list/GET_ANALYSIS_SUCCESS_COMPARE",
  GET_ANALYSIS_FAILURE_COMPARE: "list/GET_ANALYSIS_FAILURE_COMPARE",
  CLEAR_ANALYSIS: "list/CLEAR_ANALYSIS",
  CLEAR_ANALYSIS_COMPARE: "list/CLEAR_ANALYSIS_COMPARE",
  GET_INCIDENTS: "list/GET_INCIDENTS",
  GET_INCIDENTS_SUCCESS: "list/GET_INCIDENTS_SUCCESS",
  GET_INCIDENTS_FAILURE: "list/GET_INCIDENTS_FAILURE",
  STOP_TRAFFIC: "list/STOP_TRAFFIC",
  START_TRAFFIC: "list/START_TRAFFIC",
  UPDATE_OCCURENCE_ID: "list/UPDATE_OCCURENCE_ID",
};

export const Creators = {
  getAnalysis: (filters) => ({
    type: Types.GET_ANALYSIS,
    filters,
  }),
  getAnalysisSuccess: (list) => ({
    type: Types.GET_ANALYSIS_SUCCESS,
    list,
  }),
  getAnalysisFailure: () => ({
    type: Types.GET_ANALYSIS_FAILURE,
  }),
  getAnalysisCompare: (filters) => ({
    type: Types.GET_ANALYSIS_COMPARE,
    filters,
  }),
  getAnalysisSuccessCompare: (list) => ({
    type: Types.GET_ANALYSIS_SUCCESS_COMPARE,
    list,
  }),
  getAnalysisFailureCompare: () => ({
    type: Types.GET_ANALYSIS_FAILURE_COMPARE,
  }),
  clearAnalysis: () => ({
    type: Types.CLEAR_ANALYSIS,
  }),
  clearAnalysisCompare: () => ({
    type: Types.CLEAR_ANALYSIS_COMPARE,
  }),
  getIncidents: () => ({
    type: Types.GET_INCIDENTS,
  }),
  getIncidentsSuccess: (list) => ({
    type: Types.GET_INCIDENTS_SUCCESS,
    list,
  }),
  getIncidentsFailure: () => ({
    type: Types.GET_INCIDENTS_FAILURE,
  }),
  getClippings: () => ({
    type: Types.GET_CLIPPINGS,
  }),
  getClippingsSuccess: (list) => ({
    type: Types.GET_CLIPPINGS_SUCCESS,
    list,
  }),
  getClippingsFailure: () => ({
    type: Types.GET_CLIPPINGS_FAILURE,
  }),
  stopTraffic: () => ({
    type: Types.STOP_TRAFFIC,
  }),
  startTraffic: () => ({
    type: Types.START_TRAFFIC,
  }),
  updateIncidentId: (ids) => ({
    type: Types.UPDATE_OCCURENCE_ID,
    ids,
  }),
};
