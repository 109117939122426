import { Types } from "../actions/main";

const INITIAL_STATE = {
  error: false,
  userMessage: false,
  loading: false,
  mode: "map",
  mapType: "roadmap",
};

export default function main(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.REPORT_MODE:
      return {
        ...state,
        mode: "report",
      };
    case Types.MAP_MODE:
      return {
        ...state,
        mode: "map",
      };
    case Types.MAP_SATELITE:
      return {
        ...state,
        mapType: "satellite",
      };
    case Types.MAP_ROADMAP:
      return {
        ...state,
        mapType: "roadmap",
      };
    case Types.MAP_HYBRID:
      return {
        ...state,
        mapType: "hybrid",
      };
    case Types.MAP_TERRAIN:
      return {
        ...state,
        mapType: "terrain",
      };
    default:
      return state;
  }
}
