import { Button, styled, Typography } from "@mui/material";

export const Text = styled(Typography, {
  shouldForwardProp: (prop) => !["fontSize", "color"].includes(prop),
})(({ fontSize, color }) => ({
  fontFamily: "noto-sans-regular",
  fontSize: fontSize || "1.45rem",
  fontWeight: 600,
  letterSpacing: "-0.02rem",
  color: color,
  cursor: "pointer",
}));

export const FormInformation = styled(Typography, {
  shouldForwardProp: (prop) => !["marginBottom", "padding"].includes(prop),
})(({ padding, marginBottom }) => ({
  fontFamily: "noto-sans-regular",
  fontSize: "1.66rem",
  letterSpacing: "-0.02rem",
  textAlign: "center",
  padding: padding || "20px",
  marginBottom,
}));

export const InputLogin = styled("input")(() => ({
  width: "100%",
  background: "#E6E8EB",
  marginBottom: 15,
  border: "none",
  height: "50px",
  borderRadius: "5px",
  padding: "0 10px",
}));

export const VectorDiv = styled("div")(() => ({
  width: "100%",
  position: "fixed",
  bottom: "36px",
}));

export const ReturnToLogin = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const LoginMain = styled("main")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100vw",
  height: "100vh",
  background: "linear-gradient(115.08deg, #021d35 34.06%, #3ac4e0 132.98%)",
}));

export const FormContainer = styled("div")(() => ({
  gap: "8px",
  width: "410px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "90px",
  backgroundColor: "white",
  boxShadow: "5px 0px 20px rgba(0, 0, 0, 0.1)",
  padding: "20px",
  borderRadius: "10px",
  zIndex: 1,
}));

export const LoginFooter = styled("footer")(() => ({
  display: "flex",
  position: "fixed",
  bottom: 0,
  background: "white",
  width: "100%",
  height: "40px",
  alignItems: "center",
  justifyContent: "center",
}));

export const ForgotContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  margin: "15px 0px",
}));

export const SubmitButton = styled(Button)(() => ({
  background: "#3AC4E0",
  marginBottom: "20px",
  padding: "10px",
  height: "48px",
  color: "#000",
  textTransform: "none",

  transition: "all .3s linear",
  "&:hover": { backgroundColor: "#3AC4E0" },
}));

export const Divider = styled("div")(() => ({
  width: "100%",
  border: `1px solid #B1B9C0`,
}));

export const PrivacyContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  margin: "15px 0px 0px",
}));
