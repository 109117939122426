import { Types } from "../actions/auth";

const INITIAL_STATE = {
  userLogged: null,
  loggingUser: true,
  refreshEvents: true,
  ssoAuth: false,
  current_user: null,
  userData: null,
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.REQUEST_MAIN:
      return {
        ...state,
        loggingUser: false,
        error: false,
        ssoAuth: true,
      };
    case Types.SUCCESS_MAIN:
      return {
        ...state,
        current_user: action.payload,
      };
    case Types.FAILURE_MAIN:
      return {
        ...state,
        loggingUser: false,
        userLogged: null,
      };
    case Types.LOGIN:
      return {
        ...state,
        loggingUser: true,
        refreshEvents: false,
      };
    case Types.LOGIN_SUCCESS:
      return {
        ...state,
        userLogged: true,
        loggingUser: false,
        userData: action.data,
      };
    case Types.LOGIN_FAILED:
      return {
        ...state,
        loggingUser: false,
        userLogged: false,
      };
    case Types.LOGOUT:
      return {
        ...state,
        userLogged: false,
        loggingUser: false,
      };
    case Types.LOGOUT_SUCCESS:
      return {
        ...state,
        userLogged: false,
        loggingUser: false,
      };
    case Types.GET_USER_FAILED:
      return {
        ...state,
        userLogged: false,
        loggingUser: false,
      };
    case Types.ASYNC_SSO_REFRESH_AUTHENTICATE:
      return {
        ...state,
        ssoAuth: "loading",
      };
    default:
      return state;
  }
}
