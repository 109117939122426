export const Types = {
  GET_CATEGORIES: "categories/GET_CATEGORIES",
  GET_CATEGORIES_SUCCESS: "categories/GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAILURE: "categories/GET_CATEGORIES_FAILURE",
  GET_CLIPPING_CATEGORIES: "categories/GET_CLIPPING_CATEGORIES",
  GET_CLIPPING_CATEGORIES_SUCCESS: "categories/GET_CLIPPING_CATEGORIES_SUCCESS",
  GET_CLIPPING_CATEGORIES_FAILURE: "categories/GET_CLIPPING_CATEGORIES_FAILURE",
  GET_MEDIA_GROUP: "categories/GET_MEDIA_GROUP",
  GET_MEDIA_GROUP_SUCCESS: "categories/GET_MEDIA_GROUP_SUCCESS",
  GET_MEDIA_GROUP_FAILURE: "categories/GET_MEDIA_GROUP_FAILURE",
  TOGGLE_SECURITY: "Security",
  TOGGLE_TRAFFIC: "Traffic",
  TOGGLE_CONTINGENCE: "Contingence",
  RESET_CATEGORIES: "Reset",
};

export const Creators = {
  getCategories: () => ({
    type: Types.GET_CATEGORIES,
  }),
  getCategorySuccess: (categories) => ({
    type: Types.GET_CATEGORIES_SUCCESS,
    categories,
  }),
  getCategoryFailure: () => ({
    type: Types.GET_CATEGORIES_FAILURE,
  }),
  getClippingCategories: () => ({
    type: Types.GET_CLIPPING_CATEGORIES,
  }),
  getClippingCategorySuccess: (categories) => ({
    type: Types.GET_CLIPPING_CATEGORIES_SUCCESS,
    categories,
  }),
  getClippingCategoryFailure: () => ({
    type: Types.GET_CLIPPING_CATEGORIES_FAILURE,
  }),
  getMediaGroups: () => ({
    type: Types.GET_MEDIA_GROUP,
  }),
  getMediaGroupsSuccess: (mediaGroups) => ({
    type: Types.GET_MEDIA_GROUP_SUCCESS,
    mediaGroups,
  }),
  getMediaGroupsFailure: () => ({
    type: Types.GET_MEDIA_GROUP_FAILURE,
  }),
  toggleCategories: (option) => ({
    type: option,
  }),
};
