import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, getFormValues } from "redux-form";

import { Creators as Auth } from "../../store/actions/auth";

import {
  Text,
  Divider,
  InputLogin,
  SubmitButton,
  PrivacyContainer,
  ForgotContainer,
  FormInformation,
} from "./styles";
import LogoImage from "../../assets/images/logo.svg";

let LoginForm = (props) => {
  const loginEvent = () => {
    props.loginUser(props.formFields.username, props.formFields.password);
  };

  const handleChange = (event) => {
    props.change([event.target.name], event.target.value);
  };

  return (
    <>
      <img style={{ width: "285px" }} src={LogoImage} alt="Logo Geoanalytics" />
      <FormInformation>
        Insira suas credenciais para acessar o sistema de análise de dados.
      </FormInformation>
      <form onSubmit={props.handleSubmit(loginEvent)} noValidate>
        <InputLogin
          placeholder="Digite aqui o seu e-mail"
          type="email"
          name="username"
          required
          onChange={handleChange}
        />
        <InputLogin
          placeholder="Digite aqui a sua senha"
          type="password"
          name="password"
          required
          onChange={handleChange}
        />

        <SubmitButton type="submit" variant="contained" fullWidth size="large">
          <Text color={"#000000"}>Entrar</Text>
        </SubmitButton>

        <ForgotContainer>
          <Text onClick={props.openForgotPasswordForm} color="#2a93a7">
            Esqueceu a senha?
          </Text>

          <Text color="#14454E">Precisa de ajuda?</Text>
        </ForgotContainer>

        <Divider />

        <PrivacyContainer>
          <a style={{ textDecoration: "none" }}>
            <Text color="#14454E">Política de Privacidade</Text>
          </a>
        </PrivacyContainer>
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    formFields: getFormValues("LOGIN_FORM")(state),
  };
};

const mapDispatchtoProps = (dispatch) => bindActionCreators(Auth, dispatch);

LoginForm = reduxForm({ form: "LOGIN_FORM" })(LoginForm);

export default connect(mapStateToProps, mapDispatchtoProps)(LoginForm);
