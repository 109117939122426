import { Types } from "../actions/categories";

const INITIAL_STATE = {
  categoriesList: [""],
  clippingsCategories: [""],
  mediaGroups: [],
  loadingCategories: false,
  loadingClippingCategories: false,
  loadingCategoriesFailed: false,
  loadingClippingCategoriesFailed: false,
  loadingMediaGroups: false,
  loadingMediaGroupsFailed: false,
  traffic: false,
  security: false,
  contingence: false,
};

export default function list(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_CATEGORIES:
      return {
        ...state,
        loadingCategories: true,
        loadingCategoriesFailed: false,
      };
    case Types.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesList: action.categories.data,
        loadingCategories: false,
      };
    case Types.GET_CATEGORIES_FAILURE:
      return {
        ...state,
        loadingCategoriesFailed: true,
        loadingCategories: false,
      };
    case Types.GET_CLIPPING_CATEGORIES:
      return {
        ...state,
        loadingClippingCategories: true,
        loadingClippingCategoriesFailed: false,
      };
    case Types.GET_CLIPPING_CATEGORIES_SUCCESS:
      return {
        ...state,
        clippingsCategories: action.categories.data,
        loadingClippingCategories: false,
      };
    case Types.GET_CLIPPING_CATEGORIES_FAILURE:
      return {
        ...state,
        loadingClippingCategoriesFailed: true,
        loadingClippingCategories: false,
      };
    case Types.GET_MEDIA_GROUP:
      return {
        ...state,
        loadingMediaGroups: true,
        loadingMediaGroupsFailed: false,
      };
    case Types.GET_MEDIA_GROUP_SUCCESS:
      return {
        ...state,
        mediaGroups: action.mediaGroups.data,
        loadingMediaGroups: false,
      };
    case Types.GET_MEDIA_GROUP_FAILURE:
      return {
        ...state,
        loadingMediaGroupsFailed: true,
        loadingMediaGroups: false,
      };
    case Types.TOGGLE_SECURITY:
      return {
        ...state,
        security: !state.security,
      };
    case Types.TOGGLE_TRAFFIC:
      return {
        ...state,
        traffic: !state.traffic,
      };
    case Types.TOGGLE_CONTINGENCE:
      return {
        ...state,
        contingence: !state.contingence,
      };
    case Types.RESET_CATEGORIES:
      return {
        ...state,
        traffic: false,
        security: false,
        contingence: false,
      };
    default:
      return state;
  }
}
