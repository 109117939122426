export const Types = {
  REPORT_MODE: "main/REPORT_MODE",
  MAP_MODE: "main/MAP_MODE",
  MAP_SATELITE: "main/MAP_SATELITE",
  MAP_ROADMAP: "main/MAP_ROADMAP",
  MAP_HYBRID: "main/MAP_HYBRID",
  MAP_TERRAIN: "main/MAP_TERRAIN",
};

export const Creators = {
  activateReport: () => ({
    type: Types.REPORT_MODE,
  }),
  activateMap: () => ({
    type: Types.MAP_MODE,
  }),
  switchMap: (option) => ({
    type: option,
  }),
};
