import { ChevronLeft, ExpandMore } from "@mui/icons-material";
import {
  Box,
  List,
  Paper,
  Switch,
  styled,
  Drawer,
  Button,
  Select,
  ListItem,
  Accordion,
  IconButton,
  Typography,
  FormControl,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";

export const MainDrawer = styled(Drawer)(() => ({
  width: "300px",
  flexShrink: 0,
  ".MuiDrawer-paper": {
    width: "300px",
    color: "white",
    background: "#212121",
    visibility: "visible",
    overflow: "auto",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 4px rgba(0,0,0,0.3)",
      borderRadius: "10px",
      backgroundColor: "#212121",
    },
    "&::-webkit-scrollbar": {
      width: "3px",
      backgroundColor: "#212121",
      paddingRight: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      "-webkit-box-shadow": "inset 0 0 4px rgba(0,0,0,.3)",
      backgroundColor: "#424242",
    },
  },
}));

export const DrawerHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0 20px",
  marginBottom: "8px",
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  color: "#fff",
  background: "#1A1C1D",
  minHeight: "64px",
  position: "fixed",
  width: "300px",
  zIndex: 9,
}));

export const TitleText = styled(Typography)(() => ({
  width: "100%",
}));

export const ActionButton = styled(IconButton)(() => ({
  color: "#fff",
}));

export const MainSideContent = styled(Box)(() => ({
  flex: "1",
  width: "300px",
  padding: "65px 0",
  background: "#212121",
}));

export const PaperItem = styled(Paper)(() => ({
  width: "90%",
  color: "#fff",
  marginLeft: "auto",
  marginTop: "30px",
  marginRight: "auto",
  backgroundColor: "#1A1C1D",
}));

export const DateTimeContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: "20px",
}));

export const ExpandMoreIcon = styled(ExpandMore)(() => ({
  color: "#fff",
}));

export const ChevronLeftIcon = styled(ChevronLeft)(() => ({
  color: "#fff",
}));

export const SideFooter = styled(Box)(() => ({
  width: "300px",
  left: "0px",
  bottom: "10px",
  padding: "10px 0px",
  textAlign: "center",
  background: "#212121",
  color: "#fff",
}));

export const PeriodContainer = styled(Box)(() => ({
  marginLeft: "15px",
  position: "relative",
}));

export const ExpansionPanel = styled(Accordion)(() => ({
  padding: 0,
  fontSize: "0.8rem",
  background: "#1A1C1D",
}));

export const ExpasionPanelTitle = styled(AccordionSummary)(() => ({
  padding: "0 15px",
}));

export const ExpansionHeading = styled(Typography)(() => ({
  fontSize: "1.2rem",
  color: "#fff",
}));

export const ExpansionPanelDetail = styled(AccordionDetails)(() => ({
  padding: "0 15px",
}));

export const ExpansionList = styled(List)(() => ({
  width: "100%",
  padding: 0,
}));

export const ListItemComponent = styled(ListItem)(() => ({
  margin: 0,
  padding: 0,
}));

export const ItemText = styled(FormControlLabel)(() => ({
  fontSize: "0.8rem",
  marginRight: "40px",
  marginLeft: 0,
  marginBottom: 0,
  display: "flex",
  justifyContent: "flex-end",
  padding: "17px 10px",
  width: "100%",
  height: "100%",
}));

export const CustomSwitch = styled(Switch)(() => ({
  right: 0,
  position: "absolute",
}));

export const FormLabel = styled(Typography)(() => ({
  fontSize: "1rem",
  cursor: "pointer",
  color: "#fff",
}));

export const ActionContainer = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const SelectButton = styled(Button)(() => ({
  background: "#ffff",
  color: "#000000",
  opacity: 1,
  padding: "10px 0",
  fontSize: "0.8rem",
  top: "12px",
  width: "85%",
  margin: "5px 0",

  transition: "all .3s linear",
  "&:hover": {
    backgroundColor: "#545454",
    color: "#ffff",
  },
}));

export const SaveButton = styled(Button)(() => ({
  background: "#1CA495",
  color: "#000000",
  opacity: 1,
  padding: "10px 0",
  fontSize: "0.8rem",
  top: "12px",
  width: "85%",
  margin: "5px 0",

  transition: "all .3s linear",
  "&:hover": {
    backgroundColor: "#10756a",
    color: "#ffff",
  },
}));

export const CustomFormControl = styled(FormControl)(() => ({
  margin: "0px",
  width: "95%",
  padding: "0px",
  "& fieldset": {
    border: "none",
  },
}));

export const SelectPeriod = styled(Select)(() => ({
  background: "#1A1C1D",
  border: "white solid 1px",
  borderRadius: "5px",
  margin: "10px 0",
  width: "240px",
  color: "#fff",
  padding: 0,
}));

export const SelectLabel = styled(Typography)(() => ({
  color: "#fff",
}));

export const MoreButton = styled(IconButton)(() => ({
  right: "0px",
  position: "absolute",
  "& svg": {
    color: "#fff",
  },
}));

export const IncidentsCount = styled(Box)(() => ({
  backgroundColor: "#009688",
  padding: "5px 8px",
  borderRadius: "21px",
  marginLeft: "10px",
  fontSize: "10px",
}));

export const Progress = styled(CircularProgress)(() => ({
  margin: "0px 15px",
  height: "30px !important",
  width: "30px !important",
}));

export const ProviderContainer = styled("div")(() => ({
  margin: 0,
  padding: 0,
  border: "none",
}));
