import { Types } from "../actions/map";

const INITIAL_STATE = {
  mapRef: null,
  mapCenter: {
    lat: Number(process.env.REACT_APP_DEFAULT_LOCATION_LAT),
    lng: Number(process.env.REACT_APP_DEFAULT_LOCATION_LNG),
  },
  zoomMarker: 11,
  placeOnChange: false,
};

export default function map(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.MAP_CENTER:
      return {
        ...state,
        mapCenter: action.coords,
        zoomMarker: 15,
      };
    case Types.PLACE_CHANGE:
      return {
        ...state,
        placeOnChange: !state.placeOnChange,
      };
    case Types.SET_MAP_REF:
      return {
        ...state,
        mapRef: action.payload,
      };
    default:
      return state;
  }
}
