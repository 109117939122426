export const Types = {
  SET_MAP_REF: "map/SET_MAP_REF",
  MAP_CENTER: "map/MAP_CENTER",
  PLACE_CHANGE: "map/PLACE_CHANGE",
};

export const Creators = {
  mapCenter: (coords) => ({
    type: Types.MAP_CENTER,
    coords,
  }),
  setPlaceChange: () => ({
    type: Types.PLACE_CHANGE,
  }),
  setMapRef: (ref) => ({
    type: Types.SET_MAP_REF,
    payload: ref,
  }),
};
