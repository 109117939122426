const INITIAL_STATE = {
  coletivo: [
    ["Polícia Rodoviária Federal", "1", false],
    ["Áreas Urbanas com risco", "2", false],
    ["Comunicades e Facções", "3", false],
    ["Batalhões Especiais", "4", false],
    ["Delegacia Policial", "5", false],
    ["Guarda Municipal", "6", false],
    ["Batalhões da Pm", "7", false],
    ["Defesa Civil", "8", false],
    ["Ferro Velho", "9", false],
    ["Bombeiros", "10", false],
    ["Aeroporto", "11", false],
    ["Upa", "12", false],
  ],
  individual: [
    ["Linha Vermelha", "13", false],
    ["Estacionamento", "14", false],
    ["Cidade de Deus", "15", false],
    ["Linha Amarela", "16", false],
    ["Avenida Brasil", "17", false],
    ["Hospitais", "18", false],
    ["Shopping", "19", false],
    ["Central", "20", false],
  ],
};

export default function inputs(state = INITIAL_STATE) {
  return state;
}
